<template>
  <div id="addScoringResultPage">
    <h2 style="margin-bottom: 32px; text-align: center">设置评分</h2>
    <a-form
      :model="form"
      :style="{ width: '480px', margin: '0 auto' }"
      label-align="left"
      auto-label-width
      @submit="handleSubmit"
    >
      <a-form-item label="应用 id">
        {{ appId }}
      </a-form-item>
      <a-form-item v-if="updateId" label="修改评分 id">
        {{ updateId }}
      </a-form-item>
      <a-form-item field="resultName" label="结果名称">
        <a-input v-model="form.resultName" placeholder="请输入结果名称" />
      </a-form-item>
      <a-form-item field="resultDesc" label="结果描述">
        <a-input v-model="form.resultDesc" placeholder="请输入结果描述" />
      </a-form-item>
      <a-form-item
        field="resultPicture"
        label="结果图标"
        tooltip="格式：.jpg、.jpeg、.gif、.png、
        图片大小：10M以下"
      >
        <PictureUploader
          v-model="form.resultPicture"
          @input="updateAppIcon"
          ref="uploadComponent"
          :value="form.resultPicture"
        />
      </a-form-item>
      <a-form-item
        field="resultProp"
        label="结果集"
        tooltip="仅推荐测评类应用输入结果集。该项表示答案最终的结果选项集合，以MBTI测试为例，答案是I,S,T,J....
        则输入的结果集应该是[I],[S],[T],[J]，每个选项输入后需要回车确认。"
      >
        <a-input-tag
          v-model="form.resultProp"
          :style="{ width: '320px' }"
          placeholder="请输入结果集，按回车确认"
          allow-clear
        />
      </a-form-item>
      <a-form-item
        field="resultScoreRange"
        label="结果得分范围"
        tooltip="推荐得分类应用填写，该项表示大于等于当前分数。例如：5分属于A结果，则得分范围填写5。"
      >
        <a-input-number
          v-model="form.resultScoreRange"
          placeholder="请输入结果得分范围"
        />
      </a-form-item>
      <a-form-item>
        <a-space>
          <a-button type="primary" html-type="submit" style="width: 120px">
            提交
          </a-button>
          <a-button
            style="width: 120px"
            type="outline"
            status="warning"
            @click="clearForm"
            >清除
          </a-button>
        </a-space>
      </a-form-item>
    </a-form>
    <h2 style="margin-bottom: 32px">评分管理</h2>
    <ScoringResultTable :appId="appId" :doUpdate="doUpdate" ref="tableRef" />
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, withDefaults } from "vue";
import API from "@/api";
import { useRouter } from "vue-router";
import ScoringResultTable from "@/views/add/components/ScoringResultTable.vue";
import {
  addScoringResultUsingPost,
  editScoringResultUsingPost,
} from "@/api/scoringResultController";
import message from "@arco-design/web-vue/es/message";
import PictureUploader from "@/components/PictureUploader.vue";

interface Props {
  appId: string;
}

const props = withDefaults(defineProps<Props>(), {
  appId: () => {
    return "";
  },
});
const tableRef = ref();
// 表单参数
const form = ref({
  resultDesc: "",
  resultName: "",
  resultPicture: "",
} as API.ScoringResultAddRequest);

const updateId = ref<any>();

const doUpdate = async (scoringResult: API.ScoringResultVO) => {
  updateId.value = scoringResult.id;
  form.value = scoringResult;
};

const clearForm = () => {
  form.value = {
    resultDesc: "",
    resultName: "",
    resultPicture: "",
  };
  clearImage();
};
// 用于接收uploader组件传来的图片地址
const updateAppIcon = (url: string) => {
  form.value.resultPicture = url; // 更新表单中的 appIcon 属性
};

const uploadComponent = ref(null);
// 调用子组件的 handleClear 方法
const clearImage = () => {
  uploadComponent.value?.handleClear();
};

/**
 * 提交
 */
const handleSubmit = async () => {
  if (!props.appId) {
    return;
  }
  let res: any;
  // 如果是修改
  if (updateId.value) {
    res = await editScoringResultUsingPost({
      id: updateId.value as any,
      ...form.value,
    });
  } else {
    // 创建
    res = await addScoringResultUsingPost({
      appId: props.appId as any,
      ...form.value,
    });
  }
  if (res.data.code === 0) {
    message.success("操作成功");
  } else {
    message.error("操作失败，" + res.data.message);
  }
  if (tableRef.value) {
    await tableRef.value.loadData();
    updateId.value = undefined;
  }
  clearImage();
  clearForm();
};
</script>
