import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "addScoringResultPage" }

import { ref } from "vue";
import API from "@/api";
import { useRouter } from "vue-router";
import ScoringResultTable from "@/views/add/components/ScoringResultTable.vue";
import {
  addScoringResultUsingPost,
  editScoringResultUsingPost,
} from "@/api/scoringResultController";
import message from "@arco-design/web-vue/es/message";
import PictureUploader from "@/components/PictureUploader.vue";

interface Props {
  appId: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AddScoringResultPage',
  props: {
    appId: { default: () => {
    return "";
  } }
  },
  setup(__props: any) {

const props = __props;
const tableRef = ref();
// 表单参数
const form = ref({
  resultDesc: "",
  resultName: "",
  resultPicture: "",
} as API.ScoringResultAddRequest);

const updateId = ref<any>();

const doUpdate = async (scoringResult: API.ScoringResultVO) => {
  updateId.value = scoringResult.id;
  form.value = scoringResult;
};

const clearForm = () => {
  form.value = {
    resultDesc: "",
    resultName: "",
    resultPicture: "",
  };
  clearImage();
};
// 用于接收uploader组件传来的图片地址
const updateAppIcon = (url: string) => {
  form.value.resultPicture = url; // 更新表单中的 appIcon 属性
};

const uploadComponent = ref(null);
// 调用子组件的 handleClear 方法
const clearImage = () => {
  uploadComponent.value?.handleClear();
};

/**
 * 提交
 */
const handleSubmit = async () => {
  if (!props.appId) {
    return;
  }
  let res: any;
  // 如果是修改
  if (updateId.value) {
    res = await editScoringResultUsingPost({
      id: updateId.value as any,
      ...form.value,
    });
  } else {
    // 创建
    res = await addScoringResultUsingPost({
      appId: props.appId as any,
      ...form.value,
    });
  }
  if (res.data.code === 0) {
    message.success("操作成功");
  } else {
    message.error("操作失败，" + res.data.message);
  }
  if (tableRef.value) {
    await tableRef.value.loadData();
    updateId.value = undefined;
  }
  clearImage();
  clearForm();
};

return (_ctx: any,_cache: any) => {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_input_tag = _resolveComponent("a-input-tag")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[7] || (_cache[7] = _createElementVNode("h2", { style: {"margin-bottom":"32px","text-align":"center"} }, "设置评分", -1)),
    _createVNode(_component_a_form, {
      model: form.value,
      style: { width: '480px', margin: '0 auto' },
      "label-align": "left",
      "auto-label-width": "",
      onSubmit: handleSubmit
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, { label: "应用 id" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.appId), 1)
          ]),
          _: 1
        }),
        (updateId.value)
          ? (_openBlock(), _createBlock(_component_a_form_item, {
              key: 0,
              label: "修改评分 id"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(updateId.value), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_a_form_item, {
          field: "resultName",
          label: "结果名称"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              modelValue: form.value.resultName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.resultName) = $event)),
              placeholder: "请输入结果名称"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "resultDesc",
          label: "结果描述"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              modelValue: form.value.resultDesc,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.resultDesc) = $event)),
              placeholder: "请输入结果描述"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "resultPicture",
          label: "结果图标",
          tooltip: "格式：.jpg、.jpeg、.gif、.png、\n        图片大小：10M以下"
        }, {
          default: _withCtx(() => [
            _createVNode(PictureUploader, {
              modelValue: form.value.resultPicture,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.resultPicture) = $event)),
              onInput: updateAppIcon,
              ref_key: "uploadComponent",
              ref: uploadComponent,
              value: form.value.resultPicture
            }, null, 8, ["modelValue", "value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "resultProp",
          label: "结果集",
          tooltip: "仅推荐测评类应用输入结果集。该项表示答案最终的结果选项集合，以MBTI测试为例，答案是I,S,T,J....\n        则输入的结果集应该是[I],[S],[T],[J]，每个选项输入后需要回车确认。"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input_tag, {
              modelValue: form.value.resultProp,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.resultProp) = $event)),
              style: { width: '320px' },
              placeholder: "请输入结果集，按回车确认",
              "allow-clear": ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "resultScoreRange",
          label: "结果得分范围",
          tooltip: "推荐得分类应用填写，该项表示大于等于当前分数。例如：5分属于A结果，则得分范围填写5。"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input_number, {
              modelValue: form.value.resultScoreRange,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.resultScoreRange) = $event)),
              placeholder: "请输入结果得分范围"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_space, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  "html-type": "submit",
                  style: {"width":"120px"}
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" 提交 ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_a_button, {
                  style: {"width":"120px"},
                  type: "outline",
                  status: "warning",
                  onClick: clearForm
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("清除 ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _cache[8] || (_cache[8] = _createElementVNode("h2", { style: {"margin-bottom":"32px"} }, "评分管理", -1)),
    _createVNode(ScoringResultTable, {
      appId: _ctx.appId,
      doUpdate: doUpdate,
      ref_key: "tableRef",
      ref: tableRef
    }, null, 8, ["appId"])
  ]))
}
}

})