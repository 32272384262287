<template>
  <div id="addQuestionPage">
    <h2 style="margin-bottom: 32px; text-align: center">设置题目</h2>
    <!-- 加载中提示 -->
    <div v-if="isLoading" class="loading-container">
      加载中，请稍候...
      <icon-loading />
    </div>
    <a-form
      v-else
      :model="questionContent"
      :style="{ width: '900px' }"
      label-align="left"
      auto-label-width
      @submit="handleSubmit"
    >
      <div style="display: flex; justify-content: flex-start">
        <a-space size="large">
          <a-form-item label="题目列表">
            <a-space size="large">
              <a-button
                status="success"
                @click="addQuestion(questionContent.length)"
              >
                底部添加题目
              </a-button>
              <!-- AI 生成抽屉 -->
              <AiGenerateQuestionDrawer
                :appId="appId"
                :onSuccess="onAiGenerateSuccess"
                :onSSESuccess="onAiGenerateSuccessSSE"
                :onSSEClose="onSSEClose"
                :onSSEStart="onSSEStart"
              />
              <a-button
                type="primary"
                html-type="submit"
                style="width: 120px"
                :disabled="buttonDisable"
              >
                提交
              </a-button>
              <a-button
                type="primary"
                status="danger"
                style="width: 120px"
                @click="clearAllQuestions"
              >
                清空所有题目
              </a-button>
            </a-space>
          </a-form-item>
          <a-form-item label="应用 id">
            {{ appId }}
          </a-form-item>
        </a-space>
      </div>
      <a-collapse :default-active-key="['0']" destroy-on-hide>
        <!-- 遍历题目 -->
        <a-collapse-item
          v-for="(question, index) in questionContent"
          :key="index"
        >
          <template #header>
            <a-space size="small">
              {{ index + 1 }}:
              <!-- 可编辑题目标题 -->
              <a-input
                v-model="question.title"
                placeholder="请输入题目标题"
                style="width: 400px"
              />
            </a-space>
          </template>
          <!-- 操作按钮 -->
          <template #extra>
            <a-space size="medium">
              <a-button @click.stop="addQuestion(index + 1)">添加题目</a-button>
              <a-button status="danger" @click.stop="deleteQuestion(index)">
                删除题目
              </a-button>
            </a-space>
          </template>
          <!-- 选项列表 -->
          <a-collapse :default-active-key="['0']">
            <a-collapse-item
              v-for="(option, optionIndex) in question.options"
              :key="optionIndex"
            >
              <template #header>
                <!-- 可编辑选项标题 -->
                <span>选项 {{ optionIndex + 1 }}</span>
              </template>
              <template #extra>
                <!-- 选项操作按钮 -->
                <a-space size="medium">
                  <a-button
                    @click.stop="addQuestionOption(question, optionIndex + 1)"
                  >
                    添加选项
                  </a-button>
                  <a-button
                    status="danger"
                    @click.stop="deleteQuestionOption(question, optionIndex)"
                  >
                    删除选项
                  </a-button>
                </a-space>
              </template>
              <!-- 选项内容 -->
              <a-form-item label="选项 key">
                <a-input v-model="option.key" placeholder="请输入选项 key" />
              </a-form-item>
              <a-form-item label="选项值">
                <a-input v-model="option.value" placeholder="请输入选项值" />
              </a-form-item>
              <a-form-item label="选项结果">
                <a-input v-model="option.result" placeholder="请输入选项结果" />
              </a-form-item>
              <a-form-item label="选项得分">
                <a-input-number
                  v-model="option.score"
                  placeholder="请输入选项得分"
                />
              </a-form-item>
            </a-collapse-item>
            <!-- 添加选项按钮 -->
            <a-button
              type="dashed"
              block
              @click="addQuestionOption(question, question.options.length)"
              style="width: 100%"
              >底部添加选项
            </a-button>
          </a-collapse>
        </a-collapse-item>
      </a-collapse>
      <!-- 添加题目按钮 -->
      <a-button
        type="dashed"
        block
        @click="addQuestion(questionContent.length)"
      >
        底部添加题目
      </a-button>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, watchEffect, withDefaults } from "vue";
import API from "@/api";
import { useRouter } from "vue-router";
import {
  addQuestionUsingPost,
  editQuestionUsingPost,
  listQuestionVoByPageUsingPost,
} from "@/api/questionController";
import message from "@arco-design/web-vue/es/message";
import AiGenerateQuestionDrawer from "@/views/add/components/AiGenerateQuestionDrawer.vue";

interface Props {
  appId: string;
}

const props = withDefaults(defineProps<Props>(), {
  appId: () => {
    return "";
  },
});

const router = useRouter();
const isLoading = ref(true);

// 题目内容结构（题目列表）
const questionContent = ref<API.QuestionContentDTO[]>([]);

/**
 * 添加题目
 * @param index
 */
const addQuestion = (index: number) => {
  questionContent.value.splice(index, 0, {
    title: "",
    options: [],
  });
};

/**
 * 删除题目
 * @param index
 */
const deleteQuestion = (index: number) => {
  questionContent.value.splice(index, 1);
};

/**
 * 添加题目选项
 * @param question
 * @param index
 */
const addQuestionOption = (question: API.QuestionContentDTO, index: number) => {
  if (!question.options) {
    question.options = [];
  }
  question.options.splice(index, 0, {
    key: "",
    value: "",
  });
};
/**
 * 清空所有题目
 */
const clearAllQuestions = () => {
  questionContent.value = [];
  console.log("清空所有题目");
};

/**
 * 删除题目选项
 * @param question
 * @param index
 */
const deleteQuestionOption = (
  question: API.QuestionContentDTO,
  index: number
) => {
  if (!question.options) {
    question.options = [];
  }
  question.options.splice(index, 1);
};

const oldQuestion = ref<API.QuestionVO>();
/**
 * 加载数据
 */
const loadData = async () => {
  if (!props.appId) {
    return;
  }
  const res = await listQuestionVoByPageUsingPost({
    appId: props.appId as any,
    current: 1,
    pageSize: 1,
    sortField: "createTime",
    sortOrder: "descend",
  });
  if (res.data.code === 0 && res.data.data?.records) {
    oldQuestion.value = res.data.data?.records[0];
    if (oldQuestion.value) {
      questionContent.value = oldQuestion.value.questionContent ?? [];
    }
    isLoading.value = false;
  } else {
    message.error("获取数据失败，" + res.data.message);
    isLoading.value = false;
  }
};

// 获取旧数据
watchEffect(() => {
  loadData();
});

const buttonDisable = ref(false);
/**
 * 提交
 */
const handleSubmit = async () => {
  if (!props.appId || !questionContent.value) {
    return;
  }
  buttonDisable.value = true;
  let res: any;
  // 如果是修改
  if (oldQuestion.value?.id) {
    res = await editQuestionUsingPost({
      id: oldQuestion.value.id,
      questionContent: questionContent.value,
    });
  } else {
    // 创建
    res = await addQuestionUsingPost({
      appId: props.appId as any,
      questionContent: questionContent.value,
    });
  }
  if (res.data.code === 0) {
    message.success("操作成功，即将跳转到应用详情页");
    buttonDisable.value = false;
    setTimeout(() => {
      router.push(`/app/detail/${props.appId}`);
    }, 800);
  } else {
    message.error("操作失败，" + res.data.message);
    buttonDisable.value = false;
  }
};

/**
 * AI 生成题目成功后执行
 */
const onAiGenerateSuccess = (result: API.QuestionContentDTO[]) => {
  message.success(`AI 生成题目成功，生成 ${result.length} 道题目`);
  questionContent.value = [...questionContent.value, ...result];
};

/**
 * AI 生成题目成功后执行（SSE）
 */
const onAiGenerateSuccessSSE = (result: API.QuestionContentDTO) => {
  questionContent.value = [...questionContent.value, result];
};

/**
 * SSE 开始生成
 * @param event
 */
const onSSEStart = (event: any) => {
  message.success("开始生成");
};

/**
 * SSE 生成完毕
 * @param event
 */
const onSSEClose = (event: any) => {
  message.success("生成完毕");
};
</script>
