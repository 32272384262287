<template>
  <div id="doAnswerPage" style="width: 800px">
    <!-- 加载中提示 -->
    <div v-if="isLoading" class="loading-container">
      加载中，请稍候...
      <icon-loading />
    </div>
    <a-card v-else>
      <!-- 应用信息 -->
      <h1>{{ app.appName }}</h1>
      <p>描述：{{ app.appDesc }}</p>
      <p>题目数量：{{ questionContent.length }}</p>

      <!-- 当前题目 -->
      <h2 style="margin-bottom: 16px">
        {{ current }}、{{ currentQuestion?.title }}
      </h2>
      <div>
        <a-radio-group
          direction="vertical"
          v-model="currentAnswer"
          :options="questionOptions"
          @change="doRadioChange"
        />
      </div>

      <!-- 按钮区域 -->
      <div style="margin-top: 24px">
        <a-space size="large">
          <a-button
            type="primary"
            circle
            v-if="current < questionContent.length"
            :disabled="!currentAnswer"
            @click="nextQuestion"
          >
            下一题
          </a-button>
          <a-button
            type="primary"
            v-if="current === questionContent.length"
            :loading="submitting"
            circle
            :disabled="!currentAnswer"
            @click="doSubmit"
          >
            {{ submitting ? "评分中" : "查看结果" }}
          </a-button>
          <a-button v-if="current > 1" circle @click="prevQuestion">
            上一题
          </a-button>
        </a-space>
      </div>
    </a-card>
    <!-- 题目状态栏 -->
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  defineProps,
  reactive,
  ref,
  watchEffect,
  withDefaults,
} from "vue";
import API from "@/api";
import { useRouter } from "vue-router";
import { listQuestionVoByPageUsingPost } from "@/api/questionController";
import message from "@arco-design/web-vue/es/message";
import { getAppVoByIdUsingGet } from "@/api/appController";
import {
  addUserAnswerUsingPost,
  generateUserAnswerIdUsingGet,
} from "@/api/userAnswerController";
import { APP_TYPE_ENUM } from "@/constant/app";

interface Props {
  appId: string;
}

const props = withDefaults(defineProps<Props>(), {
  appId: () => {
    return "";
  },
});

const router = useRouter();
const isLoading = ref();

const app = ref<API.AppVO>({});
const questionContent = ref<API.QuestionContentDTO[]>([]);
const current = ref(1);
const currentQuestion = ref<API.QuestionContentDTO>({});
const questionOptions = computed(() => {
  return currentQuestion.value?.options
    ? currentQuestion.value.options.map((option) => {
        if (app.value.appType === APP_TYPE_ENUM.SCORE) {
          return {
            label: `${option.key}. ${option.value}`,
            value: option.key,
          };
        } else if (app.value.appType === APP_TYPE_ENUM.TEST) {
          return {
            label: `${option.key}. ${option.value}`,
            value: option.result,
          };
        }
      })
    : [];
});
const currentAnswer = ref<string>();
const answerList = reactive<string[]>([]);
const submitting = ref(false);
const id = ref<number>();

const generateId = async () => {
  const res = await generateUserAnswerIdUsingGet();
  if (res.data.code === 0) {
    id.value = res.data.data as any;
  } else {
    message.error("获取唯一 id 失败，" + res.data.message);
  }
};

watchEffect(() => {
  generateId();
});

const loadData = async () => {
  isLoading.value = true;
  if (!props.appId) return;

  let res: any = await getAppVoByIdUsingGet({ id: props.appId as any });
  if (res.data.code === 0) {
    app.value = res.data.data as any;
  } else {
    message.error("获取应用失败，" + res.data.message);
  }

  res = await listQuestionVoByPageUsingPost({
    appId: props.appId as any,
    current: 1,
    pageSize: 1,
    sortField: "createTime",
    sortOrder: "descend",
  });
  if (res.data.code === 0 && res.data.data?.records) {
    questionContent.value = res.data.data.records[0].questionContent;
  } else {
    message.error("获取题目失败，" + res.data.message);
  }
  isLoading.value = false;
};

watchEffect(() => {
  loadData();
});

watchEffect(() => {
  currentQuestion.value = questionContent.value[current.value - 1];
  currentAnswer.value = answerList[current.value - 1];
});

const doRadioChange = (value: string) => {
  answerList[current.value - 1] = value;
};

const jumpToQuestion = (questionNumber: number) => {
  current.value = questionNumber;
};

const nextQuestion = () => {
  if (current.value < questionContent.value.length) {
    current.value += 1;
  }
};

const prevQuestion = () => {
  if (current.value > 1) {
    current.value -= 1;
  }
};

const doSubmit = async () => {
  if (!props.appId || !answerList) return;

  submitting.value = true;
  const res = await addUserAnswerUsingPost({
    appId: props.appId as any,
    choices: answerList,
    id: id.value as any,
  });
  if (res.data.code === 0 && res.data.data) {
    await router.push(`/answer/result/${res.data.data}`);
  } else {
    message.error("提交答案失败，" + res.data.message);
  }
  submitting.value = false;
};
</script>

<style scoped>
.question-status-bar {
  margin-bottom: 16px;
}
</style>
