import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "doAnswerPage",
  style: {"width":"800px"}
}
const _hoisted_2 = {
  key: 0,
  class: "loading-container"
}
const _hoisted_3 = { style: {"margin-bottom":"16px"} }
const _hoisted_4 = { style: {"margin-top":"24px"} }

import {
  computed,
  reactive,
  ref,
  watchEffect,
} from "vue";
import API from "@/api";
import { useRouter } from "vue-router";
import { listQuestionVoByPageUsingPost } from "@/api/questionController";
import message from "@arco-design/web-vue/es/message";
import { getAppVoByIdUsingGet } from "@/api/appController";
import {
  addUserAnswerUsingPost,
  generateUserAnswerIdUsingGet,
} from "@/api/userAnswerController";
import { APP_TYPE_ENUM } from "@/constant/app";

interface Props {
  appId: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DoAnswerPage',
  props: {
    appId: { default: () => {
    return "";
  } }
  },
  setup(__props: any) {

const props = __props;

const router = useRouter();
const isLoading = ref();

const app = ref<API.AppVO>({});
const questionContent = ref<API.QuestionContentDTO[]>([]);
const current = ref(1);
const currentQuestion = ref<API.QuestionContentDTO>({});
const questionOptions = computed(() => {
  return currentQuestion.value?.options
    ? currentQuestion.value.options.map((option) => {
        if (app.value.appType === APP_TYPE_ENUM.SCORE) {
          return {
            label: `${option.key}. ${option.value}`,
            value: option.key,
          };
        } else if (app.value.appType === APP_TYPE_ENUM.TEST) {
          return {
            label: `${option.key}. ${option.value}`,
            value: option.result,
          };
        }
      })
    : [];
});
const currentAnswer = ref<string>();
const answerList = reactive<string[]>([]);
const submitting = ref(false);
const id = ref<number>();

const generateId = async () => {
  const res = await generateUserAnswerIdUsingGet();
  if (res.data.code === 0) {
    id.value = res.data.data as any;
  } else {
    message.error("获取唯一 id 失败，" + res.data.message);
  }
};

watchEffect(() => {
  generateId();
});

const loadData = async () => {
  isLoading.value = true;
  if (!props.appId) return;

  let res: any = await getAppVoByIdUsingGet({ id: props.appId as any });
  if (res.data.code === 0) {
    app.value = res.data.data as any;
  } else {
    message.error("获取应用失败，" + res.data.message);
  }

  res = await listQuestionVoByPageUsingPost({
    appId: props.appId as any,
    current: 1,
    pageSize: 1,
    sortField: "createTime",
    sortOrder: "descend",
  });
  if (res.data.code === 0 && res.data.data?.records) {
    questionContent.value = res.data.data.records[0].questionContent;
  } else {
    message.error("获取题目失败，" + res.data.message);
  }
  isLoading.value = false;
};

watchEffect(() => {
  loadData();
});

watchEffect(() => {
  currentQuestion.value = questionContent.value[current.value - 1];
  currentAnswer.value = answerList[current.value - 1];
});

const doRadioChange = (value: string) => {
  answerList[current.value - 1] = value;
};

const jumpToQuestion = (questionNumber: number) => {
  current.value = questionNumber;
};

const nextQuestion = () => {
  if (current.value < questionContent.value.length) {
    current.value += 1;
  }
};

const prevQuestion = () => {
  if (current.value > 1) {
    current.value -= 1;
  }
};

const doSubmit = async () => {
  if (!props.appId || !answerList) return;

  submitting.value = true;
  const res = await addUserAnswerUsingPost({
    appId: props.appId as any,
    choices: answerList,
    id: id.value as any,
  });
  if (res.data.code === 0 && res.data.data) {
    await router.push(`/answer/result/${res.data.data}`);
  } else {
    message.error("提交答案失败，" + res.data.message);
  }
  submitting.value = false;
};

return (_ctx: any,_cache: any) => {
  const _component_icon_loading = _resolveComponent("icon-loading")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createTextVNode(" 加载中，请稍候... ")),
          _createVNode(_component_icon_loading)
        ]))
      : (_openBlock(), _createBlock(_component_a_card, { key: 1 }, {
          default: _withCtx(() => [
            _createElementVNode("h1", null, _toDisplayString(app.value.appName), 1),
            _createElementVNode("p", null, "描述：" + _toDisplayString(app.value.appDesc), 1),
            _createElementVNode("p", null, "题目数量：" + _toDisplayString(questionContent.value.length), 1),
            _createElementVNode("h2", _hoisted_3, _toDisplayString(current.value) + "、" + _toDisplayString(currentQuestion.value?.title), 1),
            _createElementVNode("div", null, [
              _createVNode(_component_a_radio_group, {
                direction: "vertical",
                modelValue: currentAnswer.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentAnswer).value = $event)),
                options: questionOptions.value,
                onChange: doRadioChange
              }, null, 8, ["modelValue", "options"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_a_space, { size: "large" }, {
                default: _withCtx(() => [
                  (current.value < questionContent.value.length)
                    ? (_openBlock(), _createBlock(_component_a_button, {
                        key: 0,
                        type: "primary",
                        circle: "",
                        disabled: !currentAnswer.value,
                        onClick: nextQuestion
                      }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode(" 下一题 ")
                        ])),
                        _: 1
                      }, 8, ["disabled"]))
                    : _createCommentVNode("", true),
                  (current.value === questionContent.value.length)
                    ? (_openBlock(), _createBlock(_component_a_button, {
                        key: 1,
                        type: "primary",
                        loading: submitting.value,
                        circle: "",
                        disabled: !currentAnswer.value,
                        onClick: doSubmit
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(submitting.value ? "评分中" : "查看结果"), 1)
                        ]),
                        _: 1
                      }, 8, ["loading", "disabled"]))
                    : _createCommentVNode("", true),
                  (current.value > 1)
                    ? (_openBlock(), _createBlock(_component_a_button, {
                        key: 2,
                        circle: "",
                        onClick: prevQuestion
                      }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode(" 上一题 ")
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }))
  ]))
}
}

})